
import { Vue, Component } from "vue-property-decorator";
import {
  InvitationListDataSource,
  ListDataSource,
} from "@/data/List/ListDataSource";
import {
  mdiPen,
  mdiTrashCan,
  mdiMagnify,
  mdiDotsVertical,
  mdiPlusCircleOutline,
  mdiFolderOpen,
  mdiFolder,
  mdiAutorenew,
} from "@mdi/js";
import { toFilterString } from "@/cms-services/helpers";
import userModule from "@/store/modules/user";
import DateTimeEditor from "@/components/date-time-range.vue";
import { Filter } from "@/cms-services/filter";
import moment from "moment";

@Component({
  components: {
    DateTimeEditor,
  },
})
export default class Home extends Vue {
  moment = moment;
  userModule = userModule;
  icons: any = {
    pen: mdiPen,
    trash: mdiTrashCan,
    search: mdiMagnify,
    dotsVertical: mdiDotsVertical,
    circlePlus: mdiPlusCircleOutline,
    folderOpen: mdiFolderOpen,
    folder: mdiFolder,
    refresh: mdiAutorenew,
  };
  filter = {
    securityGroupId: 0,
    searchText: "",
  };

  invitationFilter = {
    createDateRange: [],
    sentDateRange: [],
  };

  securityGroupId: any = null;
  dataSource: InvitationListDataSource = new InvitationListDataSource({
    pageIndex: 1,
    pageSize: 15,
  });

  async download() {
    await this.dataSource.downloadStatistic(this.filter.securityGroupId);
  }
  filterString: string = "";
  async updateDateFilter() {
    let filter = Object.entries(this.invitationFilter)
      .filter(([, v]) => v.length > 1)
      .map(([k, v]) => new Filter(k, v.join(",")));
    if (!filter.length) return;
    const filterString = JSON.stringify(filter);
    if (filterString == this.filterString) return;
    this.filterString = filterString;
    await this.dataSource.getStatistic("invitationFilter=" + filterString);
  }
  clearFilterString: string | null = null;
  async clearFilter(excludeKey: string) {
    let filter = Object.entries(this.invitationFilter)
      .filter(([k, v]) => k != excludeKey && v.length > 1)
      .map(([k, v]) => new Filter(k, v.join(",")));

    const filterString =
      filter.length > 0 ? "invitationFilter=" + JSON.stringify(filter) : "";
    if (filterString == this.clearFilterString) return;
    this.clearFilterString = filterString;
    await this.dataSource.getStatistic(filterString);
  }

  headers: any[] = [
    {
      text: "ФИО",
      value: "userProfile.fullName",
      sortable: false,
    },
    {
      text: "Созданные",
      value: "created",
      align: "center",
      sortable: false,
    },
    {
      text: "Отправленные",
      value: "sended",
      align: "center",
      sortable: false,
    },
    {
      text: "Принятые",
      value: "accepted",
      align: "center",
      sortable: false,
    },
    {
      text: "Период создания",
      value: "createDates",
      sortable: false,
    },
    {
      text: "Период отправления",
      value: "sendedDates",
      sortable: false,
    },
  ];

  async updateFilter() {
    this.dataSource.config.filter = toFilterString(this.filter);
    await this.dataSource.getStatistic();
  }

  securityGroupDataSource: ListDataSource = new ListDataSource({
    config: {
      pageIndex: 1,
      pageSize: 1000,
    },
    className: "securityGroup",
  });

  get breadcrumbs() {
    return [
      {
        text: "Главная",
        to: "/",
      },
      {
        text: "Статистика по приглашениям",
        disabled: true,
      },
    ];
  }

  async created() {
    await Promise.all([
      this.updateFilter(),
      this.securityGroupDataSource.get(),
    ]);
  }
}
